import React from 'react'
import styled from 'styled-components'

const Email = styled.input`
  height: 48px;
  width: 321px;
  background-color: rgba(9, 19, 33, 0.06);
  border: 0;
  padding: 12px 16px;
  font-family: 'Helvetica Neue';
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 24px;
  box-sizing: border-box;
  vertical-align: top;
  &::placeholder {
    color: #777777;
  }
`

const Submit = styled.input`
  height: 48px;
  width: 124px;
  background-color: #0065f2;
  color: #ffffff;
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  text-align: center;
  border: none;
  box-sizing: border-box;
  vertical-align: top;
`

const SignupForm = () => (
  <form
    action="https://buttondown.email/api/emails/embed-subscribe/GarthDB"
    method="post"
    target="popupwindow"
    onsubmit="window.open('https://buttondown.email/GarthDB', 'popupwindow')"
    className="embeddable-buttondown-form"
  >
    <Email
      type="email"
      name="email"
      id="bd-email"
      placeholder="Enter your email address"
    />
    <input type="hidden" value={1} name="embed" />
    <Submit type="submit" value="Subscribe" />
  </form>
)

export default SignupForm
