import React from 'react'
import { graphql } from 'gatsby'

import BlogList from '../components/blog-list'
import Layout from '../components/layout'
import Hero from '../components/hero'

const IndexPage = ({ data }) => (
  <Layout>
    <Hero siteTitle={data.site.siteMetadata.title} image={data.heroImage} />
    <BlogList posts={data.allMdx.edges} />
  </Layout>
)

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
    heroImage: file(relativePath: { eq: "header-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 445) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            date(formatString: "")
            title
            slug
            categories
            description
          }
        }
      }
    }
  }
`

export default IndexPage
