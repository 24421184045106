import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import SignupForm from './signup-form'

const Container = styled.div`
  background: #fff;
  width: 100%;
`
const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1110px;
  padding: 130px 1.0875rem 160px;
  display: flex;
  justify-content: space-between;
`

const Title = styled.h1`
  font-size: 56px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 60px;
  width: 445px;
  margin: 34px 0 23px;
`

const Description = styled.p`
  width: 445px;
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 32px;
`

const StyledImage = styled(Img)`
  max-width: 445px;
  width: 100%;
  max-height: 380px;
  margin: 0 55px;
`

const Content = styled.div``

const Header = ({ siteTitle, image }) => (
  <Container>
    <Wrapper>
      <Content>
        <Title>{siteTitle}</Title>
        <Description>
          You'd never build a house without a blueprint, so why write CSS
          without a plan? A strong foundation makes CSS more scalable, reusable,
          and maintainable. Learn how to define a cohesive design system and
          robust architecture for your own CSS projects.
        </Description>
        <SignupForm />
      </Content>
      <StyledImage
        fluid={image.childImageSharp.fluid}
        width="445px"
        height="300px"
      />
    </Wrapper>
  </Container>
)

export default Header
