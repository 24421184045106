import React from 'react'
import styled from 'styled-components'
import DateTime from './datetime'

const Container = styled.article`
  margin: 0 auto;
  position: relative;
  max-width: 730px;
  padding: 60px 65px;
  box-sizing: border-box;
  background: white;
  &:nth-last-child(n + 2) {
    margin-bottom: 30px;
  }
`

const Title = styled.h2`
  font-family: 'Helvetica Neue';
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 48px;
  margin: 0 0 10px;
`

const Dateline = styled.div`
  opacity: 0.8;
  font-family: 'Helvetica Neue';
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 21px;
`

const Description = styled.div`
  height: 112px;
  width: 600px;
  font-family: 'Helvetica Neue';
  font-size: 18px;
  line-height: 28px;
`

const ReadMore = styled.a`
  color: #0065f2;
  text-decoration: none;
`

const PostBlurb = ({ post }) => (
  <Container>
    <Title>{post.title}</Title>
    <Dateline>
      <DateTime datetime={post.date} /> • Garth Braithwaite
    </Dateline>
    <Description>
      {post.description} <ReadMore href={post.slug}>Read more</ReadMore>
    </Description>
  </Container>
)

export default PostBlurb
