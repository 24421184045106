import React from 'react'
import styled from 'styled-components'

import PostBlurb from './post-blurb'

const Content = styled.div`
  background: #fbfbfb;
  padding: 100px 0;
`

const BlogList = ({ posts }) => (
  <Content>
    {posts.map(post => (
      <PostBlurb post={post.node.fields} />
    ))}
  </Content>
)

export default BlogList
